import React, { useEffect, useState } from 'react'
import CropCircle2 from '../Component/CourseComponent/CropCircle2'
import { Helmet } from 'react-helmet'
import { domain } from '../App'
import Blogs from '../Component/HomeComponent/Blogs'
import EventsNews from '../Component/HomeComponent/EventsNews'

const InternshipTraning = () => {
  let [activeCircle, setActiveCircle] = useState(0)
  let data = [
    {
      heading: ' High School Graduates : ',
      para: ` Dive into different career fields and gain valuable experience that will help you choose your future educational and career 
      paths.`
    },
    {
      heading: 'College Students :',
      para: ` Perfect for those wanting to put their classroom lessons into practice. Experience how various industries work and connect your studies with real-world
       applications. `
    },
    {
      heading: ' Aspiring Entrepreneurs :  ',
      para: `Explore the inner workings of different industries, from manufacturing to business strategies, and gain the knowledge to fuel your entrepreneurial 
      dreams. `
    },
    {
      heading: ' Engineering Students :  ',
      para: `See your technical skills in action across various sectors, from product development to cutting-edge technologies, and understand how these industries 
      operate.`
    },
    {
      heading: 'Management Students :  ',
      para: `Get a closer look at business operations, from managing supply chains to making strategic decisions, and deepen your understanding of effective management.
      `
    }, {
      heading: 'Educators and Faculty :',
      para: `Offer your students hands-on industry experience to enrich their learning and bridge the gap between theoretical knowledge and practical skills.`
    },
    {
      heading: 'Career Switchers : ',
      para: `Explore new career options and industries to gain insights and make informed decisions about your next professional step.`
    }, {
      heading: 'Tech Enthusiasts : ',
      para: `Engage with the latest technologies and innovations, from software development to high-tech applications, and see how they impact various industries.`
    }, 
  ]
  let sixPoints = [
    {
      heading: 'Mentorship and Career Guidance',
      short: '1: Guidance',
      para: `By understanding the exact background of the student/candidate (degree, year of passing, percentage, etc.) and their career vision, we provide mentorship and career 
          guidance.`
    },
    {
      heading: 'Project Based Learning',
      short: '2: Project learning ',
      para: `With a proper industry-inducted syllabus under the mentorship of technical consultants, students will be learning both theoretical and project-based scenarios of real-world applications.`
    },
    {
      heading: 'Work Integrated Learning',
      short: '3: Work ',
      para: `By starting with a capstone project and various levels of software development life cycles, eligible candidates will be exposed to client projects.`
    },
    {
      heading: 'Soft Skills',
      short: '4: Soft Skills',
      para: `By understanding the importance of communication skills, problem-solving and critical thinking, resume building, and other interpersonal skills, we nurture them to bridge the resource quality gap between industry expectations and candidate current
           standards.`
    },
    {
      heading: 'Interview Opportunity',
      short: '5: Interview',
      para: `We provide this service through HR consultations. We open the gateway for their income career through our HR consulting 
          vertical.`
    },
    {
      heading: 'Dream Carrier ',
      short: '6: Carrier',
      para: `By understanding the exact background of the student/candidate (degree, year of passing, percentage, etc.) and their career vision, we provide mentorship and career 
          guidance.`
    },
  ]
  let whyData = [
    {
      heading: 'Industry Experience : ',
      para: `Get hands-on experience in dynamic industries, learning from real projects and gaining practical skills that make you job-ready.`
    },
    {
      heading: 'Professional Guidance :',
      para: `Collaborate with industry professionals who provide mentorship and insights, helping you understand the nuances of your chosen field. `
    },
    {
      heading: 'Immersive Learning :  ',
      para: `Enjoy the flexibility to choose internships that fit your schedule, whether you’re a student, professional, or someone exploring a new career path. `
    },
    {
      heading: 'Networking Opportunities :  ',
      para: `Connect with industry leaders and peers, expanding your professional network and opening doors to future career opportunities. `
    },
    {
      heading: 'Career Advancement : ',
      para: `Discover your passions, clarify your career goals, and acquire the skills needed to succeed in your professional journey.`
    },
  ]
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='min-h-[50vh] bgsec' >
      <> <Helmet>
        <title>Latest Tech News & Insights - Read Our Blog | TechTalk </title>
        <meta name="description" content="Get insightful content on all the courses and more expert tips, latest trends full of Knowledge, Inspiration and software training updates and informations." />
        <link rel="canonical" href={`${domain}/internship-traning`} />
      </Helmet>
        <h1 className='d-none'>informative blog content </h1>
        <h2 className='d-none'>best blogging tips and tricks </h2>
      </>
      <img src={require('../Assest/whyslaBanner.webp')} className='w-full ' alt="WhySla" />
      {/* Content */}
      <section className='relative w-full mt-10 my-3 sm:mt-0 pt-8 sm:pt-16 poppins'>
        <h6 style={{ zIndex: 0 }}
          className='text-5xl sm:text-7xl xl:text-8xl text-nowrap fw-bold absolute top-0 left-1/2 -translate-x-1/2 txtbgz'>
          Why Choose us
        </h6>
        <div style={{ zIndex: 6 }} className='relative '>
          <h4 className=' text-3xl sm:text-5xl text-center poppins fw-semibold text-blue-800'>
            What we Provide?
          </h4>
          <h6 className='text-center flex gap-2 mx-auto w-fit poppins text-xl sm:text-2xl fw-semibold'>
            Unlimited Possibilities
            <img src={require('../Assest/arrows.png')} alt="Arrow"
              className='w-[50px]' />
          </h6>
        </div>
      </section>
      <main className='container my-5 ' >
        <div className='col-lg-8 col-xl-8 mx-auto p-4 flex' >
          <CropCircle2 activeCircle={activeCircle} scroll='internshiptraining'
            setActiveCircle={setActiveCircle} whyChooseData={sixPoints} />
        </div>
        <div id='internshiptraining' className=' my-10 rounded contentbg p-3 poppins' >
          <h5 className='text-violet-800 ' > {sixPoints[activeCircle].heading} </h5>
          <p className='my-2 text-sm' > {sixPoints[activeCircle].para}  </p>

        </div>

        <div className='rounded my-3 bg-white  p-2 p-sm-5 '>
          <section>
            <h5 className='text-violet-800 text-3xl fw-semibold ' >
              Internship Training </h5>
            <p>Learn by doing with best Internship Programs in Bangalore by getting real-world experience designed for students as well as professionals. Our program offers a dynamic way to gain practical insights into various industries, from innovative tech startups to established corporate companies. Whether you’re exploring the complexities of software development or the logistics behind supply chain management, our internships give you a behind-the-scenes look at how things
              really work. </p>
            <p>Our Internship Training Programs are crafted to enhance your understanding and bridge the gap between academic theories and professional practice. You'll engage in hands-on projects, collaborate with industry experts, and experience how businesses operate from the ground up. These internships help you find what you’re passionate about and set the stage for your
              career.
            </p>
            <p>For those in Bangalore and beyond, our program offers flexible options to fit your schedule, making it easy to immerse yourself in a real-world environment. Join us to transform your classroom theories into real-world
              skills. </p>
            <p>This program is designed to be engaging, accessible, and rewarding for everyone, regardless of their prior experience. Get ready for an internship experience that’s not only educational but also truly impactful and
              inspiring! </p>
          </section>
          <div className=' '>
            <h5 className='text-violet-800 fw-semibold text-3xl' >Who is it for? </h5>
            {
              [...data].map((x) => (
                <div>
                  <h6 className='text-violet-800'>{x.heading} </h6>
                  <p>{x.para} </p>
                </div>
              ))
            }
          </div>
          <div className=' '>
            <h5 className='text-violet-800 fw-semibold text-3xl ' > Why choose our On-The-Job Training? </h5>
            {
              [...whyData].map((x) => (
                <div>
                  <h6 className='text-violet-800'>{x.heading} </h6>
                  <p>{x.para} </p>
                </div>
              ))
            }
          </div>
        </div>
      </main>
      <EventsNews />
      <Blogs />
    </div>
  )
}

export default InternshipTraning