import React from 'react'
import TrophyIcon from '../../Icons/TrophyIcon'
import CurveContainer from './CurveContainer'
import Slider from 'react-slick';
import TvIcon from '../../Icons/TvIcon';
import CheckIcon from '../../Icons/CheckIcon';
import SuitCaseIcon from '../../Icons/SuitCaseIcon';
import IdCardIcon from '../../Icons/IdCardIcon';
import RocketIcon from '../../Icons/RocketIcon';

const HowWeProvide = () => {
    var settings = {
        // dots: false,
        arrows: false,
        speed: 500,
        slidesToShow: 4,
        // rtl: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className='min-h-[70vh] sm:min-h-[50vh]  xl:min-h-[88vh] py-4 bgsec relative poppins '>
            <img src={require('../../Assest/Shape1.png')} alt="shape1"
                className='w-[100px] top-0 -left-5 sm:left-0 absolute ' />
            <img src={require('../../Assest/Shape2.png')} alt="shape2"
                className='w-[100px] absolute right-0 bottom-0 ' />
            <section className='relative w-full mt-10 sm:mt-0 pt-8 sm:pt-16 poppins'>
                <h6 style={{ zIndex: 0 }}
                    className='text-5xl sm:text-9xl text-nowrap fw-bold absolute top-0 left-1/2 -translate-x-1/2 txtbgz'>
                    Home Page
                </h6>
                <div style={{ zIndex: 6 }} className='relative '>
                    <h4 className=' text-3xl sm:text-5xl text-center poppins fw-semibold text-blue-800'>
                        What we Provide?
                    </h4>
                    <h6 className='text-center flex gap-2 mx-auto w-fit poppins text-xl sm:text-2xl fw-semibold'>
                        Unlimited Possibilities
                        <img src={require('../../Assest/arrows.png')} alt="Arrow"
                            className='w-[50px]' />
                    </h6>
                </div>
            </section>
            {/* Sections  */}
            <section className='curvebg flex gap-3  ' >
                <article className='container ' >

                    <Slider {...settings} className=' py-4 shadow-none' >

                        <CurveContainer Com={TrophyIcon} content='Job opportunity through community access ' />
                        <CurveContainer Com={TvIcon} content='Hybrid model learning ' />
                        <CurveContainer Com={CheckIcon} content='Client Project experience through work integrated learning ' />
                        <CurveContainer Com={RocketIcon} content='Workforce development programme through training cum recruitment. ' />
                        <CurveContainer Com={SuitCaseIcon} content='Curriculum designed to more than 100% of return on investment ' />
                        <CurveContainer Com={IdCardIcon}  size={40} content='On Job training (Start your career with offer letter) ' />


                    </Slider>
                </article>
            </section>


        </div>
    )
}

export default HowWeProvide