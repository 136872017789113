import React, { useContext } from 'react'
import RightArrow from '../../Icons/RightArrow'
import CourseCard from '../CourseCard'
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import { SlaData } from '../../Context/SLAstore';

const OurCourses = () => {
    let navigate = useNavigate()
    let { allCourses } = useContext(SlaData)
    var settings = {
        dots: false,
        arrows: false,
        speed: 500,
        slidesToShow: 4,
        rtl: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: true,
        responsive: [
            {
                breakpoint: 1131,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 870,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 530,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className='coursebg min-h-[88vh] relative poppins bg-blue-50 ' >
            <main className='relative w-full pt-6 sm:pt-16 poppins'>
                <h6 style={{ zIndex: 0 }}
                    className='text-5xl sm:text-8xl xl:text-9xl fw-bold absolute 
                    top-0 left-1/2 -translate-x-1/2 text-nowrap txtbgz'>
                    Our Courses
                </h6>
                <div style={{ zIndex: 6 }} className='relative '>
                    <h4 className='text-3xl sm:text-5xl text-center poppins fw-semibold text-blue-800'>
                        What we Provide?
                    </h4>
                    <h6 className='text-center flex gap-2 mx-auto w-fit poppins text-2xl fw-semibold'>
                        Unlimited Possibilities
                        <img src={require('../../Assest/arrows2.png')} alt="Arrow"
                            className='w-[50px]' />
                    </h6>
                </div>
            </main>
            <Slider className='container my-4 ' {...settings} rtl={false}>
                {
                    allCourses && [...allCourses].reverse().map((obj) => (
                        <CourseCard name='Java Web Development' obj={obj} />
                    ))
                }

            </Slider>
            <Slider className='container my-4 ' {...settings} >
                {
                    allCourses && [...allCourses].map((obj) => (
                        <CourseCard name='Java Web Development' obj={obj} />
                    ))
                }

            </Slider>

            <button onClick={() => navigate('/allcourse')} className='rounded-full px-4 mx-auto flex my-3 p-2 bg-blue-900 text-white ' >
                See All
            </button>

        </div>
    )
}

export default OurCourses