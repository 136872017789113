import React, { useEffect, useState } from 'react'
import CropCircle2 from '../Component/CourseComponent/CropCircle2'
import { Helmet } from 'react-helmet'
import { domain } from '../App'
import Blogs from '../Component/HomeComponent/Blogs'
import EventsNews from '../Component/HomeComponent/EventsNews'

const OnJobTraningPage = () => {
  let [activeCircle, setActiveCircle] = useState(0)
  let data = [
    {
      heading: 'New Hires and Entry-Level Employees : ',
      para: `Ideal for those looking to accelerate their job readiness with industry-specific training. This program offers valuable insights into corporate etiquettes and helps new employees gain essential job-ready 
      skills.`
    },
    {
      heading: 'Professionals Seeking Career Advancement :',
      para: `Created for those aiming to sharpen their skills and acquire practical, job-ready experience through hands-on training. This program supports career growth and helps in embracing industry best 
      practices. `
    },
    {
      heading: 'Managers and Team Leaders :  ',
      para: `Perfect for those aiming to refine their leadership skills and implement strategies to improve employee performance. Ideal for improving workplace communication and boosting employee 
      engagement. `
    },
    {
      heading: 'Organizations Focused on Improving Workplace Culture :  ',
      para: `Tailored for companies looking to develop a positive work environment by boosting employee engagement and enhancing team performance through targeted on-the-job 
      training.`
    },

  ]
  let sixPoints = [
    {
      heading: 'Mentorship and Career Guidance',
      short: '1: Guidance',
      para: `By understanding the exact background of the student/candidate (degree, year of passing, percentage, etc.) and their career vision, we provide mentorship and career 
            guidance.`
    },
    {
      heading: 'Project Based Learning',
      short: '2: Project learning ',
      para: `With a proper industry-inducted syllabus under the mentorship of technical consultants, students will be learning both theoretical and project-based scenarios of real-world applications.`
    },
    {
      heading: 'Work Integrated Learning',
      short: '3: Work ',
      para: `By starting with a capstone project and various levels of software development life cycles, eligible candidates will be exposed to client projects.`
    },
    {
      heading: 'Soft Skills',
      short: '4: Soft Skills',
      para: `By understanding the importance of communication skills, problem-solving and critical thinking, resume building, and other interpersonal skills, we nurture them to bridge the resource quality gap between industry expectations and candidate current
             standards.`
    },
    {
      heading: 'Interview Opportunity',
      short: '5: Interview',
      para: `We provide this service through HR consultations. We open the gateway for their income career through our HR consulting 
            vertical.`
    },
    {
      heading: 'Dream Carrier ',
      short: '6: Carrier',
      para: `By understanding the exact background of the student/candidate (degree, year of passing, percentage, etc.) and their career vision, we provide mentorship and career 
            guidance.`
    },
  ]
  let whyData = [
    {
      heading: 'Engaging and Practical Learning : ',
      para: `Our program features interactive sessions that provide hands-on experience with industry-specific training, ensuring you are well-prepared to excel in your role.`
    },
    {
      heading: 'Customized for Current Work Environments  :',
      para: `Stay competitive with training that integrates the latest corporate best practices and meets the demands of modern workplaces. `
    },
    {
      heading: 'Flexible Learning Options :  ',
      para: `Choose from a range of flexible modules that accommodate your schedule, whether you're looking for on-the-job training in Bangalore or remote learning 
      options. `
    },
    {
      heading: 'Real-World Experience :  ',
      para: `Our approach emphasizes practical application, helping you gain job-ready skills that you can immediately use to improve work performance and advance your 
      career. `
    },
    {
      heading: 'Guidance from Experts : ',
      para: `Receive valuable insights from seasoned professionals who are dedicated to enhancing your professional development and improving workplace culture.`
    },
  ]
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='min-h-[50vh] bgsec' >
      <> <Helmet>
        <title>Latest Tech News & Insights - Read Our Blog | TechTalk </title>
        <meta name="description" content="Get insightful content on all the courses and more expert tips, latest trends full of Knowledge, Inspiration and software training updates and informations." />
        <link rel="canonical" href={`${domain}/on-job-traing`} />
      </Helmet>
        <h1 className='d-none'>informative blog content </h1>
        <h2 className='d-none'>best blogging tips and tricks </h2>
      </>
      <img src={require('../Assest/whyslaBanner.webp')} className='w-full ' alt="WhySla" />
      {/* Content */}
      <section className='relative w-full mt-10 my-3 sm:mt-0 pt-8 sm:pt-16 poppins'>
        <h6 style={{ zIndex: 0 }}
          className='text-5xl sm:text-7xl xl:text-8xl text-nowrap fw-bold absolute top-0 left-1/2 -translate-x-1/2 txtbgz'>
          Why Choose us
        </h6>
        <div style={{ zIndex: 6 }} className='relative '>
          <h4 className=' text-3xl sm:text-5xl text-center poppins fw-semibold text-blue-800'>
            What we Provide?
          </h4>
          <h6 className='text-center flex gap-2 mx-auto w-fit poppins text-xl sm:text-2xl fw-semibold'>
            Unlimited Possibilities
            <img src={require('../Assest/arrows.png')} alt="Arrow"
              className='w-[50px]' />
          </h6>
        </div>
      </section>
      <main className='container my-5 ' >
        <div className='col-lg-8 col-xl-8 mx-auto p-4 flex' >
          <CropCircle2 activeCircle={activeCircle} scroll='onjobtraning'
            setActiveCircle={setActiveCircle} whyChooseData={sixPoints} />
        </div>
        <div id='onjobtraning' className=' my-10 rounded contentbg p-3 poppins' >
          <h5 className='text-violet-800 ' > {sixPoints[activeCircle].heading} </h5>
          <p className='my-2 text-sm' > {sixPoints[activeCircle].para}  </p>

        </div>

        <div className='rounded my-3 bg-white  p-2 p-sm-5 '>
          <section>
            <h5 className='text-violet-800 text-3xl fw-semibold ' >
              On-The-Job Training </h5>
            <p> Gaining practical experience while working can significantly enhance your career prospects and professional skills. Participating in on-the-job training offers a crucial chance to develop job-ready skills directly within a real work setting.This hands-on approach not only allows you to perform tasks related to your role but also helps you understand industry-specific practices and
              technologies. </p>
            <p> Companies that offer best on-the-job training are often recognized for their commitment to employee growth. These programs focus on providing workplace learning opportunities that directly translate into improved job performance and professional development. These training programs can greatly enhance career growth by equipping employees with the essential skills and insights required to excel in their
              positions.</p>
            <p>The benefits of on-the-job training extend beyond immediate job performance. Employees who participate in these programs often find themselves better prepared for future career opportunities and challenges. The practical skills and experience gained through these programs contribute to a more polished and professional image, making individuals stand out in the job
              market. </p>
            <p> If you're looking for on-the-job training in Bangalore or elsewhere, SkillLearningAcademy’s training program offers a wide range of opportunities to boost your skills and career prospects. This type of learning is becoming increasingly favored by both employers and employees, proving its value in creating a versatile and capable
              workforce.  </p>
          </section>
          <div className=' '>
            <h5 className='text-violet-800 fw-semibold text-3xl' >Who is it for? </h5>
            {
              [...data].map((x) => (
                <div>
                  <h6 className='text-violet-800'>{x.heading} </h6>
                  <p>{x.para} </p>
                </div>
              ))
            }
          </div>
          <div className=' '>
            <h5 className='text-violet-800 fw-semibold text-3xl ' > Why choose our On-The-Job Training? </h5>
            {
              [...whyData].map((x) => (
                <div>
                  <h6 className='text-violet-800'>{x.heading} </h6>
                  <p>{x.para} </p>
                </div>
              ))
            }
          </div>
        </div>
      </main>
      <EventsNews />
      <Blogs />
    </div>
  )
}

export default OnJobTraningPage