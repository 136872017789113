import React from 'react'
import BulbIcon from '../../Icons/BulbIcon'

const JourneyCourse = () => {
    return (
        <div className='xl:min-h-[88vh]  relative' >
            <img src={require('../../Assest/spiral.png')} className='absolute top-0 w-32   ' alt="Spiral" />
            <img src={require('../../Assest/arrow2.png')} alt="Spring" className='w-20 absolute d-none d-sm-block top-20 right-10 ' />
            <section className='relative w-full mt-10 sm:mt-0 pt-8 sm:pt-16 poppins'>
                <h6 style={{ zIndex: 0 }}
                    className='text-5xl sm:text-9xl text-nowrap fw-bold absolute top-0 left-1/2 -translate-x-1/2 txtbgz'>
                    Journey
                </h6>
                <div style={{ zIndex: 6 }} className='relative '>
                    <h4 className=' text-3xl sm:text-5xl text-center poppins fw-semibold text-blue-800'>
                        What we Provide?
                    </h4>
                    <h6 className='text-center flex gap-2 mx-auto w-fit poppins text-xl sm:text-2xl fw-semibold'>
                        Unlimited Possibilities
                        <img src={require('../../Assest/arrows.png')} alt="Arrow"
                            className='w-[50px] ' />
                    </h6>
                </div>
            </section>
            {/* journey things */}
            <main className='my-4 relative ' >

                <img src={require('../../Assest/lady.png')} alt="Lady" className='absolute d-none d-sm-block left-0 w-32 bottom-10' />
                <img src={require('../../Assest/men.png')} alt="Mens" className='absolute d-none d-sm-block right-0 w-32 bottom-20 ' />
                <section className=' sm:border-b-2 poppins row container mx-auto border-violet-700  ' >
                    <article className=' col-sm-3 mt-4 mt-sm-0 flex relative min-h-[20rem] ' >
                        <div className='text-center mx-auto text-sm w-[15rem] h-[16rem] relative p-3 rounded-xl journeycard ' >
                            <div className='bulbbg text-white relative rounded-full mx-auto bottom-9 w-fit p-2' >
                                <BulbIcon />
                            </div>
                            <div className='star bg-white absolute w-[9px] h-[9px] top-6 left-5 '> </div>
                            <div className='star bg-white absolute w-[9px] h-[9px] top-6 right-5 '> </div>

                            <h4 className='text-center text-slate-200 text-lg '>#1 Partnership </h4>
                            <p className='text-xs text-slate-300 ' >
                                Strategic collaboration with a tech company on a live project
                                focused at developing a new software feature or application.</p>
                        </div>
                        <hr className='rotate-90 d-none d-sm-block w-8 border-2 border-violet-700 
                        opacity-100 absolute bottom-0 left-1/2 -translate-x-1/2 ' />

                    </article>
                    {/* 2 */}
                    <article className=' col-sm-3 mt-4 mt-sm-0 flex relative  min-h-[16rem] ' >
                        <div className='text-center mx-auto text-sm w-[15rem] h-[16rem] relative p-3 rounded-xl journeycard ' >
                            <div className='bulbbg text-white relative rounded-full mx-auto bottom-9 w-fit p-2' >
                                <BulbIcon />
                            </div>
                            <div className='star bg-white absolute w-[9px] h-[9px] top-6 left-5 '> </div>
                            <div className='star bg-white absolute w-[9px] h-[9px] top-6 right-5 '> </div>

                            <h4 className='text-center text-slate-200 text-lg '>#3 Student Teams</h4>
                            <p className='text-xs text-slate-300 ' >
                                We handle all aspects of vetting and each team will work on the different aspects of the project, such as frontend
                                development, backend development, and quality assurance. </p>
                        </div>
                        <hr className='rotate-90 d-none d-sm-block w-8 border-2 border-violet-700 
                        opacity-100 absolute bottom-0 left-1/2 -translate-x-1/2 ' />

                    </article>
                    {/* 3 */}
                    <article className=' col-sm-3 mt-4 mt-sm-0 relative flex   min-h-[16rem] ' >
                        <div className='text-center mx-auto text-sm w-[15rem] h-[16rem] relative p-3 rounded-xl journeycard ' >
                            <div className='bulbbg text-white relative rounded-full mx-auto bottom-9 w-fit p-2' >
                                <BulbIcon />
                            </div>
                            <div className='star bg-white absolute w-[9px] h-[9px] top-6 left-5 '> </div>
                            <div className='star bg-white absolute w-[9px] h-[9px] top-6 right-5 '> </div>

                            <h4 className='text-center text-slate-200 text-lg '>#5 Progress Monitoring</h4>
                            <p className='text-xs text-slate-300 ' >
                                Properly analyse weekly check-ins and progress reports to monitor the project’s developmental
                                process and point out any drawbacks to be resolved.</p>
                        </div>
                        <hr className='rotate-90 d-none d-sm-block w-8 border-2 border-violet-700 
                        opacity-100 absolute bottom-0 left-1/2 -translate-x-1/2 ' />

                    </article>
                    {/* 4 */}
                    <article className=' col-sm-3 mt-4 mt-sm-0 flex relative min-h-[16rem] ' >
                        <div className='text-center mx-auto text-sm w-[15rem] h-[16rem] relative p-3 rounded-xl journeycard ' >
                            <div className='bulbbg text-white relative rounded-full mx-auto bottom-9 w-fit p-2' >
                                <BulbIcon />
                            </div>
                            <div className='star bg-white absolute w-[9px] h-[9px] top-6 left-5 '> </div>
                            <div className='star bg-white absolute w-[9px] h-[9px] top-6 right-5 '> </div>

                            <h4 className='text-center text-slate-200 text-lg '>#7 Assessment </h4>
                            <p className='text-xs text-slate-300 ' >
                                Evaluate the projects based on technical execution, adherence to project requirements, teamwork, and innovation.
                                Provide detailed feedback and grades. </p>
                        </div>
                        <hr className='rotate-90 d-none d-sm-block w-8 border-2 border-violet-700 
                        opacity-100 absolute bottom-0 left-1/2 -translate-x-1/2 ' />

                    </article>
                </section>

                {/* Bottom */}
                <section className=' poppins row container mx-auto ' >
                    <article className=' col-sm-3 mt-4 mt-sm-0 relative flex min-h-[20rem] ' >
                        <div className='text-center mt-auto mx-auto mx-sm-0 ms-sm-auto text-sm w-[15rem] h-[16rem]
                         relative p-3 rounded-xl journeycard ' >
                            <div className='bulbbg text-white relative rounded-full mx-auto bottom-9 w-fit p-2' >
                                <BulbIcon />
                            </div>
                            <div className='star bg-white absolute w-[9px] h-[9px] top-6 left-5 '> </div>
                            <div className='star bg-white absolute w-[9px] h-[9px] top-6 right-5 '> </div>

                            <h4 className='text-center text-slate-200 text-lg '> #2 Project Scope </h4>
                            <p className='text-xs text-slate-300 ' >
                                Figure out a project to understand basic user requirements, design specifications,
                                coding, testing, and deployment. Make sure the project build with real-world challenges
                                and utilises current technologies. </p>
                        </div>
                        <hr className='rotate-90 d-none d-sm-block w-8 border-2 border-violet-700 
                        opacity-100 absolute top-0 left-1/2 translate-x-1/2 ' />

                    </article>
                    {/* 2 */}
                    <article className=' col-sm-3 mt-4 mt-sm-0 relative flex  min-h-[16rem] ' >
                        <div className='text-center mt-auto mx-auto mx-sm-0 ms-sm-auto text-sm w-[15rem] h-[16rem]
                         relative p-3 rounded-xl journeycard ' >
                            <div className='bulbbg text-white relative rounded-full mx-auto bottom-9 w-fit p-2' >
                                <BulbIcon />
                            </div>
                            <div className='star bg-white absolute w-[9px] h-[9px] top-6 left-5 '> </div>
                            <div className='star bg-white absolute w-[9px] h-[9px] top-6 right-5 '> </div>

                            <h4 className='text-center text-slate-200 text-lg '>#4 Mentorship </h4>
                            <p className='text-xs text-slate-300 ' >
                                Assign professional domain expert mentors to each team to provide guidance,
                                feedback and similar skills through the project. </p>
                        </div>
                        <hr className='rotate-90 d-none d-sm-block w-8 border-2 border-violet-700 
                        opacity-100 absolute top-0 left-1/2 translate-x-1/2 ' />

                    </article>
                    {/* 3 */}
                    <article className=' col-sm-3 mt-4 mt-sm-0 relative flex  min-h-[16rem] ' >
                        <div className='text-center mt-auto mx-auto mx-sm-0 ms-sm-auto text-sm w-[15rem] h-[16rem]
                         relative p-3 rounded-xl journeycard ' >
                            <div className='bulbbg text-white relative rounded-full mx-auto bottom-9 w-fit p-2' >
                                <BulbIcon />
                            </div>
                            <div className='star bg-white absolute w-[9px] h-[9px] top-6 left-5 '> </div>
                            <div className='star bg-white absolute w-[9px] h-[9px] top-6 right-5 '> </div>

                            <h4 className='text-center text-slate-200 text-lg '>#6 Final Presentation </h4>
                            <p className='text-xs text-slate-300 ' >
                                Organize a final presentation event for student teams to showcase their completed projects
                                to similar industry experts, faculty, and peers. </p>
                        </div>
                        <hr className='rotate-90 d-none d-sm-block w-8 border-2 border-violet-700 
                        opacity-100 absolute top-0 left-1/2 translate-x-1/2 ' />

                    </article>
                    {/* 4 */}
                    <article className=' col-sm-3 mt-4 mt-sm-0 relative flex  min-h-[16rem] ' >
                        <div className='text-center mt-auto mx-auto mx-sm-0 ms-sm-auto text-sm w-[15rem] h-[16rem]
                         relative p-3 rounded-xl journeycard ' >
                            <div className='bulbbg text-white relative rounded-full mx-auto bottom-9 w-fit p-2' >
                                <BulbIcon />
                            </div>
                            <div className='star bg-white absolute w-[9px] h-[9px] top-6 left-5 '> </div>
                            <div className='star bg-white absolute w-[9px] h-[9px] top-6 right-5 '> </div>

                            <h4 className='text-center text-slate-200 text-lg '> #8 Reflection </h4>
                            <p className='text-xs text-slate-300 ' >
                                Have students submit a detailed project report to their learning experiences,
                                challenges faced, and skills gained during the project.</p>
                        </div>
                        <hr className='rotate-90 d-none d-sm-block w-8 border-2 border-violet-700 
                        opacity-100 absolute top-0 left-1/2 translate-x-1/2 ' />

                    </article>
                </section>


            </main>

        </div>
    )
}

export default JourneyCourse