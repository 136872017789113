import React, { useState } from 'react'
import InstructorModal from '../InstructorModal'
import StudentContact from '../StudentContact'

const BecomeInstructor = () => {
    let [instructorShow, setInstructorShow] = useState(false)
    let [studentShow, setStudentShow] = useState(false)
    return (
        <div className=' min-h-[35vh] xl:min-h-[65vh] flex ' >
            <main className='row container flex m-auto ' >
                <section className='col-lg-6 my-2 p-4 ' >
                    <div className='rounded-xl h-[180px] sm:h-[250px] relative instructorbox' >
                        <img src={require('../../Assest/thumbprint.png')}
                            className='absolute bottom-0 opacity-40 z-10' alt="Thumb" />
                        <img src={require('../../Assest/BecomInstructor.webp')} alt="Instructor"
                            className='w-[140px] sm:w-[180px] absolute bottom-0 object-contain h-fit z-20' />
                        <div className='ms-auto relative text-end p-4 text-white w-2/3 poppins z-30'>
                            <h5 className='fw-semibold text-2xl '>Become an Instructor </h5>
                            <p className='text-slate-200 hidden sm:block text-sm' >
                                To take a trivial example, which of us undertakes physical exercise yes is this happen here.
                            </p>
                            <button onClick={() => setInstructorShow(true)}
                                className='bg-violet-600 rounded-full p-2 px-4'>
                                Contact Us
                            </button>
                        </div>
                    </div>
                </section>
                <section className='col-lg-6 my-2 p-4 '  >
                    <div className='rounded-xl h-[180px] sm:h-[250px] relative studentbox' >
                        <img src={require('../../Assest/thumbprint.png')}
                            className='absolute bottom-0 opacity-40 ' alt="Thumb" />


                        <img src={require('../../Assest/BecomeStudent.webp')} alt="Student"
                            className='w-[220px] sm:w-[370px] object-contain absolute right-0 bottom-0 h-fit ' />


                        <div className=' relative p-4 text-white  w-7/12 poppins ' >
                            <h5 className='fw-semibold text-2xl '>Become a Student </h5>
                            <p className='text-slate-200 d-none d-sm-block text-sm' >To take a trivial example, which of us
                                undertakes physical exercise yes is
                                this happen here. </p>
                            <button onClick={() => setStudentShow(true)} className='bg-violet-600 rounded-full p-2 px-4 ' >
                                Contact Us
                            </button>

                        </div>

                    </div>
                </section>

            </main>
            <InstructorModal show={instructorShow} setShow={setInstructorShow} />
            <StudentContact show={studentShow} setshow={setStudentShow} />
        </div>
    )
}

export default BecomeInstructor