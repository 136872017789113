import React, { useContext } from 'react'
import Slider from 'react-slick'
import CourseCard from '../CourseCard'
import SliderRight from '../../Icons/SliderRight';
import SliderLeft from '../../Icons/SliderLeft';
import { SlaData } from '../../Context/SLAstore';

const OtherCourses = () => {
    let { allCourses } = useContext(SlaData)
    var settings = {
        // dots: false,

        speed: 500,
        slidesToShow: 4,
        // rtl: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: true,
        nextArrow: <SliderRight size={12} />,
        prevArrow: <SliderLeft size={12} />,
        responsive: [
            {
                breakpoint: 1034,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ]
    };
    return (
        <div className='othercoursesbg xl:min-h-[88vh] my-4 ' >
            <section className='relative w-full mt-10 sm:mt-0 pt-8 sm:pt-16 poppins'>
                <h6 style={{ zIndex: 0 }}
                    className='text-5xl sm:text-9xl text-nowrap fw-bold absolute top-0 left-1/2 -translate-x-1/2 txtbgz'>
                    Courses
                </h6>
                <div style={{ zIndex: 6 }} className='relative '>
                    <h4 className=' text-3xl sm:text-5xl text-center poppins fw-semibold text-blue-800'>
                        What we Provide?
                    </h4>
                    <h6 className='text-center flex gap-2 mx-auto w-fit poppins text-xl sm:text-2xl fw-semibold'>
                        Unlimited Possibilities
                    </h6>
                </div>
            </section>
            <main className=' ' >
                <Slider {...settings} className=' my-5 container mx-auto' >
                    {
                        allCourses && allCourses.map((obj) => (
                            <CourseCard obj={obj} page='otherCourse' />
                        ))
                    }
                </Slider>
            </main>
        </div>
    )
}

export default OtherCourses