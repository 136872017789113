import React from 'react'
import Slider from 'react-slick'

const CourseContent = (props) => {
  let { data, activeCircle } = props
  var settings2 = {
    dots: false,
    arrows: false,
    speed: 2000,
    slidesToShow: 4,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: true,
    className: "center",
    centerMode: true,
    centerPadding: "0px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <section id='content' className='container poppins py-5 h-fit rounded p-3 contentbg' >
      {/* Overview */}
      {activeCircle == 0 &&
        <>
          {data && data.Overview && data.Overview.map((obj) => (
            <div className='my-2 ' >
              <h4 className='text-violet-900 '>{obj.OverViewHeading} </h4>
              <p>{obj.point}</p>
            </div>
          ))}
        </>}
      {/* Who is this course for */}
      {activeCircle == 1 &&
        <>
          {/* <h4 className='text-violet-900 '>Who is this Course for? </h4> */}
          {data && data.Who && data.Who.map((obj) => (
            <div className='my-2 ' >
              <h4 className='text-violet-900 '>{obj.Heading} </h4>
              <p>{obj.point}</p>

            </div>
          ))}
        </>}
      {activeCircle == 3 && <>

        {/* <h4 className='text-violet-900 '>Career Opportunities </h4> */}
        {
          data && data.Careers && data.Careers.map((obj) => (
            <div className='my-2 ' >
              <h4 className='text-violet-900 '>{obj.CareerHeading} </h4>
              <p>{obj.Para}</p>
            </div>
          ))
        }
        {data && data.Why && data.Why.map((obj) => (
          <div className='my-2 ' >
            <h4 className='text-violet-900 '>{obj.Heading} </h4>
            <p>{obj.point}</p>
          </div>
        ))}

        <h4 className='text-violet-900 '>Job Roles & Salary Structure </h4>
        {data && data.Salary && data.Salary.map((obj) => (
          <div className='my-2 ' >
            <h6 className='text-violet-900 '>{obj.SalaryHeading} </h6>
            <p className='text-sm text-slate-800 ' >{obj.Para}</p>
            <p className='fw-semibold text-sm '>Salary Range : <span className='fw-normal ' >{obj.Salary_range} </span> </p>
          </div>))}

      </>
      }

      {
        activeCircle == 4 && <>

          <h4 className='text-violet-900 fw-semibold'>Course Outcome </h4>
          {data && data.OutCome2 && data.OutCome2.map((obj) => (
            <div className='my-2 ' >
              <h6 className='text-violet-900 '>{obj.OutComeHeading} </h6>
              <p className='text-sm '>{obj.point}</p>
            </div>))}

        </>
      }
      {
        activeCircle == 2 && <>

          <h4 className='text-violet-900 fw-semibold'>Curriculum for the {data.Title}  </h4>
          <section className='flex flex-wrap gap-3 ' >

            {data && data.Curriculum && data.Curriculum.map((obj) => (
              <div className='my-2 w-[10rem] ' >
                <h6 className='text-violet-900 '>{obj.Subject} </h6>
                {obj.Topics && obj.Topics.map((val) => (
                  <p className='text-sm mb-1 '>{val.TopicName}</p>
                ))}

              </div>))}

          </section>
        </>
      }
      {
        activeCircle == 5 && <>

          <h4 className='text-violet-900 fw-semibold'>Various package {data.Title}  </h4>
          <section className='my-4 bordergrd container poppins' >
            <Slider {...settings2} className=' ' >
              {
                data && data.Various_packages.map((val, index) => (
                  <div className={`border-r-2
                         p-3 flex h-[8rem] border-slate-400 `} >
                    <div className='flex flex-col items-center justify-center text-center ' >
                      <p className=' text-slate-600 uppercase' > {val.PackagesHeading}    </p>
                      <p className='text-sm text-slate-950 fw-semibold ' > {val.Packages_value} </p>
                    </div>
                  </div>
                ))
              }
            </Slider>
          </section>
        </>
      }
    </section >
  )
}

export default CourseContent