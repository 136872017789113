import React, { useState } from 'react'
import CropCircle2 from '../Component/CourseComponent/CropCircle2'
import { Helmet } from 'react-helmet'
import { domain } from '../App'
import Blogs from '../Component/HomeComponent/Blogs'
import EventsNews from '../Component/HomeComponent/EventsNews'

const WhySLA = () => {
    let [activeCircle, setActiveCircle] = useState(0)
    let data = [
        {
            heading: 'Structured Learning : ',
            para: `Skill learning academy provides structured learning programs designed to take you from beginner to advanced levels in a particular skill. This structure can help you stay focused and motivated as you 
            progress.`
        },
        {
            heading: 'Expert Guidance :',
            para: `Skill learning Academy typically employs experienced instructors who are experts in their respective fields. Their guidance can accelerate your learning process, provide valuable insights, and help you avoid common 
            pitfalls.`
        },
        {
            heading: 'Hands-on Experience :  ',
            para: `Skill learning academy emphasizes hands-on learning, allowing you to practice and apply what you've learned in real-world scenarios. This practical experience is invaluable for mastering a 
            skill.`
        },
        {
            heading: 'Networking Opportunities :  ',
            para: `Academy often brings together individuals with similar interests and goals. This creates opportunities to network with peers, exchange ideas, and potentially collaborate on 
            projects.`
        },
        {
            heading: 'Access to Resources :  ',
            para: `Academy provides access to resources such as textbooks, online tutorials, practice exercises, and specialized equipment or software that you may not have access to 
            otherwise.`
        }, {
            heading: 'Recognition and Credentials :',
            para: `Completing a program from Skill learning academy can provide you with recognized credentials or certifications, which can enhance your resume and credibility in the 
            field.`
        }, {
            heading: 'Personalized Feedback  : ',
            para: `nstructors at skill learning academy can provide personalized feedback on your progress, helping you identify areas for improvement and tailor your learning experience to meet your specific 
            needs.`
        }, {
            heading: 'Motivation and Accountability : ',
            para: `Being part of a structured learning environment with deadlines and expectations can help keep you accountable and motivated to continue learning and improving your skills.`
        }, {
            heading: 'Soft skills : ',
            para: `Skill learning academy not only equips you with technical skills but also trains you in soft skills so that you are better equipped to face interviews and shine with a personality that makes you ready to get a job and adjust to the work 
            atmosphere.`
        },
    ]
    let sixPoints = [
        {
            heading: 'Mentorship and Career Guidance',
            short: '1: ROI',
            para: `By understanding the exact background of the student/candidate (degree, year of passing, percentage, etc.) and their career vision, we provide mentorship and career 
            guidance.`
        },
        {
            heading: 'Project Based Learning',
            short: '2: RQG ',
            para: `With a proper industry-inducted syllabus under the mentorship of technical consultants, students will be learning both theoretical and project-based scenarios of real-world applications.`
        },
        {
            heading: 'Job portal and Job community',
            short: '3: Job portal  ',
            para: `By starting with a capstone project and various levels of software development life cycles, eligible candidates will be exposed to client projects.`
        },
        {
            heading: 'Project Based Learning ',
            short: '4: Project ',
            para: `By understanding the importance of communication skills, problem-solving and critical thinking, resume building, and other interpersonal skills, we nurture them to bridge the resource quality gap between industry expectations and candidate current
             standards.`
        },
        {
            heading: 'Multiple Certificate',
            short: '5: Certificates',
            para: `We provide this service through HR consultations. We open the gateway for their income career through our HR consulting 
            vertical.`
        },
        {
            heading: 'Job Assured ',
            short: '6: Job assured ',
            para: `By understanding the exact background of the student/candidate (degree, year of passing, percentage, etc.) and their career vision, we provide mentorship and career 
            guidance.`
        },
    ]
    return (
        <div className='min-h-[50vh] bgsec' >
            <> <Helmet>
                <title>Choose SkillLearningAcademy for Your Training Needs and Resources     </title>
                <meta name="description" content="Get our best services in our expertise and commitment to excellence in every interaction for industry-leading training, support, and training resources to your 
                success." />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="canonical" href={`${domain}/why-skilllearningacademy`} />
            </Helmet>
                <h1 className='d-none'>best training company </h1>
                <h2 className='d-none'>best training institute </h2>
            </>
            <img src={require('../Assest/whyslaBanner.webp')} className='w-full ' alt="WhySla" />
            {/* Content */}
            <section className='relative w-full mt-10 my-3 sm:mt-0 pt-8 sm:pt-16 poppins'>
                <h6 style={{ zIndex: 0 }}
                    className='text-5xl sm:text-7xl xl:text-8xl text-nowrap fw-bold absolute top-0 left-1/2 -translate-x-1/2 txtbgz'>
                    Why Choose us
                </h6>
                <div style={{ zIndex: 6 }} className='relative '>
                    <h4 className=' text-3xl sm:text-5xl text-center poppins fw-semibold text-blue-800'>
                        What we Provide?
                    </h4>
                    <h6 className='text-center flex gap-2 mx-auto w-fit poppins text-xl sm:text-2xl fw-semibold'>
                        Unlimited Possibilities
                        <img src={require('../Assest/arrows.png')} alt="Arrow"
                            className='w-[50px]' />
                    </h6>
                </div>
            </section>
            <main className='container my-5 ' >
                <div className='col-lg-8 col-xl-8 mx-auto p-4 flex' >
                    <CropCircle2 activeCircle={activeCircle} scroll='whyslascroll'
                        setActiveCircle={setActiveCircle} whyChooseData={sixPoints} />
                </div>
                <div id='whyslascroll' className=' my-10 rounded contentbg p-3 poppins' >
                    <h5 className='text-violet-800 ' > {sixPoints[activeCircle].heading} </h5>
                    <p className='my-2 text-sm' > {sixPoints[activeCircle].para}  </p>

                </div>
                <div className='rounded flex my-3 bg-white '>
                    <div className='  p-2 p-sm-5 '>
                        {
                            [...data].map((x) => (
                                <div>
                                    <h5 className='text-violet-800'>{x.heading} </h5>
                                    <p>{x.para} </p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </main>
            <EventsNews />
            <Blogs />
        </div>
    )
}

export default WhySLA