import React, { useEffect, useState } from 'react'
import CropCircle2 from '../Component/CourseComponent/CropCircle2'
import { Helmet } from 'react-helmet'
import { domain } from '../App'
import Blogs from '../Component/HomeComponent/Blogs'
import EventsNews from '../Component/HomeComponent/EventsNews'

const TraningComeRequirtment = () => {
  let [activeCircle, setActiveCircle] = useState(0)
  let data = [
    {
      heading: 'High School Graduates : ',
      para: `Start your career early by participating in Employee Training Programs that guide your future educational and professional paths.`
    },
    {
      heading: 'College Students :',
      para: `Enrich your academic journey with Training Workshops that offer practical skills and real-world applications, bridging the gap between classroom learning and professional 
      experience.`
    },
    {
      heading: ' Aspiring Professionals :  ',
      para: `Develop your expertise and credentials in your field with our Recruitment Strategies to stand out in the job market and achieve your career 
      ambitions.`
    },
    {
      heading: 'Career Changers :  ',
      para: `Explore new fields with our Corporate Training Solutions and gain certifications that facilitate a smooth transition into a new career path.`
    },
    {
      heading: 'Working Professionals :  ',
      para: `Upgrade your skills through Dual Certification and gain credentials that open doors to new opportunities and career advancement within your current field or
       beyond.`
    }, {
      heading: 'Educators and Trainers :',
      para: `Integrate our Training and Recruitment programs into your curriculum or professional development plans to provide students with valuable industry-recognized
       credentials.`
    }, {
      heading: 'Tech Enthusiasts  : ',
      para: `Dive into the latest technologies with our training solutions that validate your expertise and enhance your application of cutting-edge
       innovations.`
    },
  ]
  let sixPoints = [
    {
      heading: 'Mentorship and Career Guidance',
      short: '1: Guidance',
      para: `By understanding the exact background of the student/candidate (degree, year of passing, percentage, etc.) and their career vision, we provide mentorship and career 
            guidance.`
    },
    {
      heading: 'Project Based Learning',
      short: '2: Project learning ',
      para: `With a proper industry-inducted syllabus under the mentorship of technical consultants, students will be learning both theoretical and project-based scenarios of real-world applications.`
    },
    {
      heading: 'Work Integrated Learning',
      short: '3: Work ',
      para: `By starting with a capstone project and various levels of software development life cycles, eligible candidates will be exposed to client projects.`
    },
    {
      heading: 'Soft Skills',
      short: '4: Soft Skills',
      para: `By understanding the importance of communication skills, problem-solving and critical thinking, resume building, and other interpersonal skills, we nurture them to bridge the resource quality gap between industry expectations and candidate current
             standards.`
    },
    {
      heading: 'Interview Opportunity',
      short: '5: Interview',
      para: `We provide this service through HR consultations. We open the gateway for their income career through our HR consulting 
            vertical.`
    },
    {
      heading: 'Dream Carrier ',
      short: '6: Carrier',
      para: `By understanding the exact background of the student/candidate (degree, year of passing, percentage, etc.) and their career vision, we provide mentorship and career 
            guidance.`
    },
  ]
  let whyData = [
    {
      heading: 'Industry Experience : ',
      para: `Get hands-on experience in dynamic industries, learning from real projects and gaining practical skills that make you job-ready.`
    },
    {
      heading: 'Professional Guidance :',
      para: `Collaborate with industry professionals who provide mentorship and insights, helping you understand the nuances of your chosen field. `
    },
    {
      heading: 'Immersive Learning :  ',
      para: `Enjoy the flexibility to choose internships that fit your schedule, whether you’re a student, professional, or someone exploring a new career path. `
    },
    {
      heading: 'Networking Opportunities :  ',
      para: `Connect with industry leaders and peers, expanding your professional network and opening doors to future career opportunities. `
    },
    {
      heading: 'Career Advancement : ',
      para: `Discover your passions, clarify your career goals, and acquire the skills needed to succeed in your professional journey.`
    },
  ]
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='min-h-[50vh] bgsec' >
      <>
        <Helmet>
          <title>Latest Tech News & Insights - Read Our Blog | TechTalk </title>
          <meta name="description" content="Get insightful content on all the courses and more expert tips, latest trends full of Knowledge, Inspiration and software training updates and informations." />
          <link rel="canonical" href={`${domain}/traning-come-recruitment`} />
        </Helmet>
        <h1 className='d-none'>informative blog content </h1>
        <h2 className='d-none'>best blogging tips and tricks </h2>
      </>
      <img src={require('../Assest/whyslaBanner.webp')} className='w-full ' alt="WhySla" />
      {/* Content */}
      <section className='relative w-full mt-10 my-3 sm:mt-0 pt-8 sm:pt-16 poppins'>
        <h6 style={{ zIndex: 0 }}
          className='text-5xl sm:text-7xl xl:text-8xl text-nowrap fw-bold absolute top-0 left-1/2 -translate-x-1/2 txtbgz'>
          Why Choose us
        </h6>
        <div style={{ zIndex: 6 }} className='relative '>
          <h4 className=' text-3xl sm:text-5xl text-center poppins fw-semibold text-blue-800'>
            What we Provide?
          </h4>
          <h6 className='text-center flex gap-2 mx-auto w-fit poppins text-xl sm:text-2xl fw-semibold'>
            Unlimited Possibilities
            <img src={require('../Assest/arrows.png')} alt="Arrow"
              className='w-[50px]' />
          </h6>
        </div>
      </section>
      <main className='container my-5 ' >
        <div className='col-lg-8 col-xl-8 mx-auto p-4 flex' >
          <CropCircle2 activeCircle={activeCircle} scroll='traningcome'
            setActiveCircle={setActiveCircle} whyChooseData={sixPoints} />
        </div>
        <div id='traningcome' className=' my-10 rounded contentbg p-3 poppins' >
          <h5 className='text-violet-800 ' > {sixPoints[activeCircle].heading} </h5>
          <p className='my-2 text-sm' > {sixPoints[activeCircle].para}  </p>
        </div>
        <div className='rounded my-3 bg-white  p-2 p-sm-5 '>
          <section>
            <h5 className='text-violet-800 text-3xl fw-semibold ' >
              Internship Training </h5>
            <p>Effective employee development and hiring practices are crucial for organizational success. At SkillLearningAcademy we address both Employee Training and Recruitment needs with practical, industry-focused strategies. By providing targeted Employee Training Programs and refining Recruitment Strategies, we equip you with the essential tools and knowledge to enhance your workforce and hiring processes. Our programs are designed to meet industry standards and support your specific goals in both training and
              recruitment. </p>
            <p>Our programs cover a range of topics, including Corporate Training Solutions that focus on effective employee development and best practices for onboarding new employees. You’ll engage in real-world scenarios and learn strategies that directly impact your hiring and training processes.

            </p>
            <p>The benefits of our Training Workshops extend beyond theoretical knowledge. They equip you with the skills to improve communication, problem-solving, and overall effectiveness in a professional environment. For those seeking flexibility, our Hybrid Classes combine the convenience of online learning with the interactive aspects of in-person sessions.
            </p>
            <p>Our approach includes practical experiences such as Internship opportunities and Capstone Projects, allowing you to apply what you've learned in a real-world context and build a robust portfolio that stands out to potential employers.
            </p>
          </section>
          <div className=' '>
            <h5 className='text-violet-800 fw-semibold text-3xl' >Who is it for? </h5>
            {
              [...data].map((x) => (
                <div>
                  <h6 className='text-violet-800'>{x.heading} </h6>
                  <p>{x.para} </p>
                </div>
              ))
            }
          </div>
          <div className=' '>
            <h5 className='text-violet-800 fw-semibold text-3xl ' > Why choose our On-The-Job Training? </h5>
            {
              [...whyData].map((x) => (
                <div>
                  <h6 className='text-violet-800'>{x.heading} </h6>
                  <p>{x.para} </p>
                </div>
              ))
            }
          </div>
        </div>
      </main>
      <EventsNews />
      <Blogs />
    </div>
  )
}

export default TraningComeRequirtment