import React, { useEffect, useState } from 'react'
import PLusIcon from '../../Icons/PLusIcon'
import QuestionIcon from '../../Icons/QuestionIcon'
import ArrowDown from '../../Icons/ArrowDown'

const FAQ = ({ data }) => {
    let [showAns, setShowAns] = useState(-1)
    let faqs = [
        ...data.FAQ]
    let [fillteredFaq, setFilteredFaq] = useState()
    let assignAns = (id) => {
        if (showAns != id) {
            setShowAns(id)
        }
        else
            setShowAns(-1)
    }
    useEffect(() => {
        if (data)
            setFilteredFaq(data.FAQ.slice(0, 4))
    }, [data.id])
    return (
        <div className=' py-3 xl:min-h-[88vh] bg-blue-50 ' >
            <section className='relative w-full mt-10 sm:mt-0 pt-8 sm:pt-16 poppins'>
                <h6 style={{ zIndex: 0 }}
                    className='text-5xl sm:text-9xl text-nowrap fw-bold absolute top-0 left-1/2 -translate-x-1/2 txtbgz'>
                    FAQ
                </h6>
                {console.log(data.FAQ, "faq")
                }
                <div style={{ zIndex: 6 }} className='relative '>
                    <h4 className=' text-3xl sm:text-5xl text-center poppins fw-semibold text-blue-800'>
                        What we Provide?
                    </h4>
                    <h6 className='text-center flex gap-2 mx-auto w-fit poppins text-xl sm:text-2xl fw-semibold'>
                        Unlimited Possibilities
                        <img src={require('../../Assest/arrows.png')} alt="Arrow"
                            className='w-[50px]' />
                    </h6>
                </div>
            </section>
            {/* Faqs */}

            <main className='row container mx-auto my-5 ' >
                <section className='col-md-6 p-4 ' >
                    {
                        fillteredFaq && fillteredFaq.slice(0, Math.round(fillteredFaq.length / 2)).map((obj, index) => (
                            <article className='bg-white mb-3 rounded p-3  ' >
                                <div className='flex justify-between items-center ' >
                                    <p className='mb-0 '> {obj.Question} </p>
                                    <button onClick={() => assignAns(obj.id)}
                                        className={` ${showAns == obj.id && 'rotate-45 '} duration-300 `} >
                                        <PLusIcon />
                                    </button>
                                </div>
                                {showAns == obj.id &&
                                    < div className='text-sm text-slate-400 w-4/5 ' >
                                        {obj.Answer}
                                    </div>}
                            </article>
                        ))
                    }

                </section>
                <section className='col-md-6 p-4 ' >
                    {/* <article className=' rounded border-2 text-center  border-blue-900 p-3 bg-white' >
                        <div className='w-fit mx-auto my-3 ' >
                            <QuestionIcon />
                        </div>
                        <h4 className='text-xl ' > Do you have more questions? </h4>
                        <p className='text-sm text-slate-400 ' >Lorem ipsum dolor sit amet consectetur. Quam libero viverra
                            faucibus condimentum. </p>
                        <a href="mailto:info@skilllearningacademy" className='text-decoration-none ' >

                            <button className=' bg-blue-900 text-decoration-none 
                             rounded p-2 px-3 text-white text-center ' >
                                Shoot a direct mail
                            </button>
                        </a>
                    </article> */}
                    {
                        fillteredFaq && fillteredFaq.slice(Math.round(fillteredFaq.length / 2)).map((obj, index) => (
                            <article className='bg-white mb-3 rounded p-3  ' >
                                <div className='flex justify-between items-center ' >
                                    <p className='mb-0 '> {obj.Question} </p>
                                    <button onClick={() => assignAns(obj.id)}
                                        className={` ${showAns == obj.id && 'rotate-45 '} duration-300 `} >
                                        <PLusIcon />
                                    </button>
                                </div>
                                {showAns == obj.id &&
                                    < div className='text-sm text-slate-400 w-4/5 ' >
                                        {obj.Answer}
                                    </div>}
                            </article>
                        ))
                    }

                </section>

                {faqs.length > 4 && <div className='flex gap-3 ' >
                    <div className='opacity-100 border-2 h-fit border-t-2 w-full relative top-3 border-opacity-40 border-violet-700 ' >
                    </div>
                    <button
                        onClick={() => {
                            fillteredFaq.length > 4 ?
                                setFilteredFaq(data.FAQ.slice(0, 4)) :
                                setFilteredFaq(data.FAQ)

                        }}
                        className='text-sm flex items-center gap-2 text-nowrap fw-semibold '>
                        {fillteredFaq && fillteredFaq.length > 4 ? "See Less " : "See All"}
                        <span className={` ${fillteredFaq && fillteredFaq.length > 4 && 'rotate-180'} duration-300 `} >
                            <ArrowDown />
                        </span>
                    </button>
                </div>}
            </main>

        </div >
    )
}

export default FAQ